import React from "react";
import { useQuery } from "@tanstack/react-query";
import {
  getAllProductTypes,
  getAOV,
  getBundleProduct,
  getBundleProductsCustomer,
  getCollectionProducts,
  getCollections,
  getCollectionsChartData,
  getConversionRatio,
  getCustomerJourney,
  getCustomers,
  getFirstBuyers,
  getFrequentCustomer,
  getOrderComparison,
  getPagesBounceRate,
  getPageViews,
  getProductsAddedToCartButNotSold,
  getProductsByType,
  getProductsGraphData,
  getProductsSpan,
  getProductsViews,
  getProductTypes,
  getProductViews,
  getRegionalOrders,
  getRevenueComparison,
  getROIData,
  getSalesChannelsViews,
  getSellingMonth,
  getSkuData,
  getSocialMediaCustomers,
  getStocksVariantData,
  getStore,
  getStoreProducts,
  getSyncProgressStats,
  getValueableCustomer,
  getVendor,
  getViewsCitiess,
  getViewsState,
} from "../utils/Queries.js";

//general data hooks
export const useSyncStoreProgress = () => {
  return useQuery({
    queryKey: ["progress"],
    queryFn: () => getSyncProgressStats(),
  });
};

export const useStore = ({ onSuccess, onError, manual }) => {
  return useQuery({
    queryKey: ["store"],
    queryFn: () => getStore(),
    onSuccess: (data) => {
      onSuccess(data);
    },
    onError: (error) => {
      onError(error);
    },
    manual,
    refetchInterval: 10000,
  });
};
export const useAOV = ({ startDate, endDate }) => {
  return useQuery({
    queryKey: ["aov", { startDate, endDate }],
    queryFn: () => getAOV({ startDate, endDate }),
    keepPreviousData: true,
  });
};

//customers hooks
export const useFrequentCustomers = ({
  startDate,
  endDate,
  limit,
  page = 1,
  noOfOrders,
  comparisonType = "",
}) => {
  return useQuery({
    queryKey: [
      "frequentCustomer",
      { startDate, endDate, limit, page, noOfOrders, comparisonType },
    ],
    queryFn: () =>
      getFrequentCustomer({
        startDate,
        endDate,
        limit,
        page,
        noOfOrders,
        comparisonType,
      }),
    keepPreviousData: true,
  });
};
export const useFisrtBuyers = ({
  startDate,
  endDate,
  firstBuyersPerProduct,
  topMostBuyersLength,
}) => {
  return useQuery({
    queryKey: [
      "frequentCustomer",
      { firstBuyersPerProduct, topMostBuyersLength, startDate, endDate },
    ],
    queryFn: () =>
      getFirstBuyers({
        firstBuyersPerProduct,
        topMostBuyersLength,
        startDate,
        endDate,
      }),
    keepPreviousData: true,
  });
};

export const useValueableCustomers = ({
  startDate,
  endDate,
  limit,
  page = 1,
  sortBy = "revenue",
  noOfOrders,
  comparisonType = "",
}) => {
  return useQuery({
    queryKey: [
      "valueableCustomers",
      {
        startDate,
        endDate,
        limit,
        page,
        sortBy,
        noOfOrders,
        comparisonType,
      },
    ],
    queryFn: () =>
      getValueableCustomer({
        startDate,
        endDate,
        limit,
        page,
        sortBy,
        noOfOrders,
        comparisonType,
      }),
    keepPreviousData: true,
  });
};

//product hooks
export const useGetProductsSpan = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
  type = "",
}) => {
  return useQuery({
    queryKey: [
      "getProductsSpan",
      { region, state, startDate, endDate, page, perPage, type },
    ],
    queryFn: () =>
      type
        ? getProductsByType({
            region,
            state,
            startDate,
            endDate,
            page,
            perPage,
            type,
          })
        : getProductsSpan({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetProductsByTypes = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
  type,
}) => {
  return useQuery({
    queryKey: [
      "getProductsByType",
      { region, state, startDate, endDate, page, perPage, type },
    ],
    queryFn: () =>
      getProductsByType({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
        type,
      }),
    keepPreviousData: true,
  });
};

export const useGetProductTypes = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getProductTypes",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getProductTypes({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetProductsGraph = ({ region, startDate, endDate }) => {
  return useQuery({
    queryKey: ["getProductsGraph", { region, startDate, endDate }],
    queryFn: () => getProductsGraphData({ region, startDate, endDate }),
  });
};

//regional data hooks
export const useGetRegionalOrders = ({
  limit,
  startDate,
  endDate,
  dashboard,
  region,
  state,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getRegionalOrders",
      { limit, startDate, endDate, dashboard, region, state, page, perPage },
    ],
    queryFn: () =>
      getRegionalOrders({
        limit,
        startDate,
        endDate,
        dashboard,
        region,
        state,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

//collections hooks
export const useGetCollections = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getCollections",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getCollections({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetCollectionProducts = ({ id, page }) => {
  return useQuery({
    queryKey: ["getCollectionProducts", { id, page }],
    queryFn: () => getCollectionProducts({ id, page }),
    keepPreviousData: true,
  });
};
export const useGetCollectionsChart = ({
  collections,
  region,
  state,
  startDate,
  endDate,
}) => {
  return useQuery({
    queryKey: [
      "getCollectionsGraph",
      { collections, region, state, startDate, endDate },
    ],
    queryFn: () =>
      getCollectionsChartData({
        collections,
        region,
        state,
        startDate,
        endDate,
      }),
    keepPreviousData: true,
  });
};

//sku

export const useGetSkuData = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "getSkuData",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getSkuData({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

export const useGetROIData = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage,
}) => {
  return useQuery({
    queryKey: [
      "geROIData",
      { region, state, startDate, endDate, page, perPage },
    ],
    queryFn: () =>
      getROIData({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

//bundles
export const useGetBundleProduct = ({ startDate, endDate, minThreshold }) => {
  return useQuery({
    queryKey: ["getBundleProduct", { startDate, endDate, minThreshold }],
    queryFn: () => getBundleProduct({ startDate, endDate, minThreshold }),
    keepPreviousData: true,
  });
};

export const useGetBundleProductsCustomer = ({
  startDate,
  endDate,
  minThreshold,
}) => {
  return useQuery({
    queryKey: [
      "getBundleProductsCustomer",
      { startDate, endDate, minThreshold },
    ],
    queryFn: () =>
      getBundleProductsCustomer({ startDate, endDate, minThreshold }),
    keepPreviousData: true,
  });
};

//stocks and wherehouses

export const useGetStocksVariantData = ({ page, onSuccess }) => {
  return useQuery({
    queryKey: ["getStocksVariantData", { page }],
    queryFn: () => getStocksVariantData({ page }),
    keepPreviousData: true,
    onSuccess: (data) => {
      onSuccess(data.data);
    },
  });
};

export const useGetSellingMonth = ({ sortBy, enabled }) => {
  return useQuery({
    queryKey: ["getSellingMonth", { sortBy }],
    queryFn: () => getSellingMonth({ sortBy }),
    keepPreviousData: true,
    enabled,
  });
};

export const useOrderComparison = ({
  firstMonth,
  secondMonth,
  city,
  state,
}) => {
  return useQuery({
    queryKey: ["OrderComparison", { firstMonth, secondMonth, city, state }],
    queryFn: () => getOrderComparison({ firstMonth, secondMonth, city, state }),
    keepPreviousData: true,
  });
};
export const useRevenueComparison = ({
  firstMonth,
  secondMonth,
  city,
  state,
}) => {
  return useQuery({
    queryKey: ["RevenueComparison", { firstMonth, secondMonth, city, state }],
    queryFn: () =>
      getRevenueComparison({ firstMonth, secondMonth, city, state }),
    keepPreviousData: true,
  });
};

export const useVendors = () => {
  return useQuery({
    queryKey: ["vendors"],
    queryFn: () => getVendor(),
    keepPreviousData: true,
  });
};

export const useProductTypes = () => {
  return useQuery({
    queryKey: ["getAllProductTypes"],
    queryFn: () => getAllProductTypes(),
    keepPreviousData: true,
  });
};
export const useCustomers = () => {
  return useQuery({
    queryKey: ["getCustomers"],
    queryFn: () => getCustomers(),
    keepPreviousData: true,
  });
};
export const useStoreProducts = () => {
  return useQuery({
    queryKey: ["getStoreProducts"],
    queryFn: () => getStoreProducts(),
    keepPreviousData: true,
  });
};

export const useGetProductViewed = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getProductViewed", { page }],
    queryFn: () =>
      getProductsViews({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

export const useGetPageViews = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getPageViews", { page }],
    queryFn: () =>
      getPageViews({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};

export const useGetProductViews = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getProductViews", { page }],
    queryFn: () =>
      getProductViews({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

export const useGetCustomerJourney = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getCustomerJourney", { page }],
    queryFn: () =>
      getCustomerJourney({ region, state, startDate, endDate, page, perPage }),
    keepPreviousData: true,
  });
};
export const useGetSocialMediaCustomers = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getSocialMediaCustomers", { page }],
    queryFn: () =>
      getSocialMediaCustomers({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

export const useGetConversionRatio = ({ startDate, endDate }) => {
  return useQuery({
    queryKey: ["getConversionRatio"],
    queryFn: () => getConversionRatio({ startDate, endDate }),
    keepPreviousData: true,
  });
};

export const useGetSalesChannelsViews = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getSalesChannelsViews", { page }],
    queryFn: () =>
      getSalesChannelsViews({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

export const useGetPagesBounceRate = ({
  region,
  state,
  startDate,
  endDate,
  sortBy,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getPagesBounceRate", { page }],
    queryFn: () =>
      getPagesBounceRate({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
        sortBy,
      }),
    keepPreviousData: true,
  });
};

export const useGetProductsAddedToCartButNotSold = ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  return useQuery({
    queryKey: ["getProductsAddedToCartButNotSold", { page }],
    queryFn: () =>
      getProductsAddedToCartButNotSold({
        region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      }),
    keepPreviousData: true,
  });
};

export const useGetViewsStates = ({ startDate, endDate, key }) => {
  return useQuery({
    queryKey: ["getViewsStates", { startDate, endDate, key }],
    queryFn: () =>
      getViewsState({
        startDate,
        endDate,
        key,
      }),
    keepPreviousData: true,
  });
};
