import API from "./ApiCall";

export const getStates = async ({
  endDate,
  startDate,
  key,
  idsArray,
  type,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/stores/getState`,
      params: {
        endDate,
        startDate,
        key,
        idsArray,
        type,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAllStates = async () => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/stores/getAllStates`,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
export const getAllCitiesOfAStates = async ({ state }) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/stores/getCitiesOfAState`,
      params: {
        state,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getCity = async ({
  endDate,
  startDate,
  key,
  state,
  idsArray,
  type,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/stores/getCity`,
      params: {
        state,
        endDate,
        startDate,
        key,
        idsArray,
        type,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSyncProgressStats = async () => {
  const { data } = await API({
    method: "get",
    url: `/api/stores/syncProgress`,
  });
  return data;
};

export const getDateRangeLimit = async () => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/stores/getDateRange`,
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetchStoreOrders = async () => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/fetchOrders`,
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const fetchStoreCollections = async () => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/fetchCollections`,
    });
    return jsonData;
  } catch (err) {
    console.log("fetchStoreCollections error : ", err.message);
    return err;
  }
};
export const getStoreCities = async () => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/getCities`,
    });
    return jsonData;
  } catch (err) {
    console.log("getStoreCities error : ", err);
    return err;
  }
};
export const getStore = async () => {
  const {
    data: { data: jsonData },
  } = await API({
    method: "get",
    url: `/api/stores/getStore`,
  });
  return jsonData;
};
export const getVendor = async () => {
  try {
    const { data: jsonData } = await API({
      method: "get",
      url: `/api/stores/vendors`,
    });
    return jsonData;
  } catch (err) {
    console.log("getVendor : ", err);
    return err;
  }
};
export const getCustomers = async () => {
  try {
    const { data: jsonData } = await API({
      method: "get",
      url: `/api/stores/customers`,
    });
    return jsonData;
  } catch (err) {
    console.log("getCustomers : ", err);
    return err;
  }
};
export const setVendor = async ({ data }) => {
  try {
    const { data: jsonData } = await API({
      method: "post",
      url: `/api/stores/vendors`,
      data,
    });
    return jsonData;
  } catch (err) {
    console.log("setVendors : ", err);
    return err;
  }
};

export const setConfiguration = async ({ data }) => {
  try {
    const { data: jsonData } = await API({
      method: "post",
      url: `/api/stores/updateConfiguration`,
      data,
    });
    return jsonData;
  } catch (err) {
    console.log("getVendor : ", err);
    return err;
  }
};

export const getAllProductTypes = async () => {
  try {
    const { data: jsonData } = await API({
      method: "get",
      url: `/api/products/getAllProductTypes`,
    });
    return jsonData;
  } catch (err) {
    console.log("getAllProductTypes : ", err);
    return err;
  }
};
export const getStoreProducts = async () => {
  try {
    const { data: jsonData } = await API({
      method: "get",
      url: `/api/stores/getStoreProducts`,
    });
    return jsonData;
  } catch (err) {
    console.log("getStoreProducts : ", err);
    return err;
  }
};

//get locations data of variants and their quantities
export const getWarehosueLocationData = async ({ ids }) => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/stores/storeLocations?ids=${ids}&perPage=5`,
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//get variants data for stocks screen
export const getStocksVariantData = async ({ page, perPage = 20 }) => {
  const { data } = await API({
    method: "get",
    url: `/api/variants/getStocksVariant`,
    params: {
      page,
      perPage,
    },
  });
  return data;
};

export const getRegionalOrders = async ({
  startDate,
  endDate,
  limit = null,
  dashboard,
  region,
  state,
  page,
  perPage = 20,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/orders/getRegionOrders`,
    params: {
      startDate,
      endDate,
      limit,
      dashboard,
      region,
      state,
      page,
      perPage,
    },
  });
  return data;
};
export const getSellingMonth = async ({ sortBy = "highest" }) => {
  const { data } = await API({
    method: "get",
    url: `/api/orders/getSellingMonth`,
    params: {
      sortBy,
    },
  });
  return data;
};
export const getProductsSpan = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/topProducts`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
      perPage,
    },
  });
  return data;
};

export const getProductTypes = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/topProductTypes`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
      perPage,
    },
  });
  return data;
};

export const getProductsByType = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
  type,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/getProductsByType`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
      perPage,
      type,
    },
  });
  return data;
};
export const getProductsGraphData = async ({
  region,
  state,
  startDate,
  endDate,
  productIds = null,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/topProductsGraph`,
    params: {
      productIds,
      region,
      state,
      startDate,
      endDate,
    },
  });
  return data;
};

export const getProductViewsGraphData = async ({
  productId,
  city,
  state,
  startDate,
  endDate,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/products//getProductViewsGraph`,
    params: {
      productId,
      city,
      state,
      startDate,
      endDate,
    },
  });
  return data;
};

export const getProductsViews = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/products/getProductsView`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getProductsViewsChart = async ({
  product_id,
  region,
  startDate,
  endDate,
  page,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/getProductsViewsChart`,
      params: {
        product_id,
        region,
        startDate,
        endDate,
        page,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getROIData = async ({
  region,
  state,
  startDate,
  endDate,
  page,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/variants/getROIData`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
    },
  });
  return data;
};

export const getSkuData = async ({
  region,
  state,
  startDate,
  endDate,
  page,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/variants/getSkuData`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
      perPage: 20,
    },
  });
  return data;
};

export const getSkuGraphData = async ({
  variants,
  region,
  state,
  startDate,
  endDate,
}) => {
  try {
    const {
      data: { skuData: jsonData },
    } = await API({
      method: "get",
      url: `/api/variants/getSkuGraphData`,
      params: {
        variants,
        state,
        region,
        startDate,
        endDate,
      },
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getROIChartData = async ({
  variants,
  state,
  region,
  startDate,
  endDate,
}) => {
  try {
    const {
      data: { roiData: jsonData },
    } = await API({
      method: "get",
      url: `/api/getROIChartData`,
      params: {
        variants,
        state,
        region,
        startDate,
        endDate,
      },
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

//
// export const getTopProducts = async () => {
//   try {
//     const {
//       data: { data: jsonData },
//     } = await API({
//       method: "get",
//       url: `/api/getTopProducts`,
//     });
//     return jsonData;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

// export const getTopProductSpan = async ({
//   region,
//   startDate,
//   endDate,
//   productId,
// }) => {
//   try {
//     console.log("getTopProductSpan", region, startDate, endDate, productId);
//     const {
//       data: { data: jsonData },
//     } = await API({
//       method: "get",
//       url: `/api/getTopProductSpan`,
//       params: {
//         region,
//         startDate,
//         endDate,
//         productId,
//       },
//     });
//     return jsonData;
//   } catch (err) {
//     console.log(err);
//     return err;
//   }
// };

export const getProductLifecycle = async ({
  region,
  state,
  span,
  startDate,
  endDate,
  productId,
}) => {
  try {
    const {
      data: { data: jsonData },
    } = await API({
      method: "get",
      url: `/api/products/getProductLifeCycle`,
      params: {
        region,
        state,
        span,
        startDate,
        endDate,
        productId,
      },
    });
    return jsonData;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getCollections = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 20,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/collections/topCollections`,
    params: {
      region,
      state,
      startDate,
      endDate,
      page,
      perPage,
    },
  });
  return data;
};

export const getCollectionsChartData = async ({
  collections,
  region,
  state,
  startDate,
  endDate,
}) => {
  const {
    data: { data: jsonData },
  } = await API({
    method: "get",
    url: `/api/collections/getCollectionsChartData`,
    params: {
      collections,
      region,
      state,
      startDate,
      endDate,
    },
  });
  return jsonData;
};

export const getAllPlans = async () => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/plan/getAllPlans`,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const makePayment = async ({ id }) => {
  try {
    const { data } = await API({
      method: "post",
      url: `/api/billing/makePayment`,
      data: {
        id,
        return_url: process.env.REACT_APP_URL,
        test: "true",
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const subscribePromotionalPlan = async () => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/billing/subscribePromotionalPlan`,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const confirmPayment = async ({ charge_id }) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/billing/paymentConfirmation?charge_id=${charge_id}`,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const sendActivity = async ({ body }) => {
  try {
    const { data } = await API({
      method: "post",
      url: `/api/trackEvent`,
      data: body,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getAOV = async ({ startDate = "", endDate = "" }) => {
  const { data } = await API({
    method: "get",
    url: `/api/stores/aov`,
    params: {
      startDate,
      endDate,
    },
  });
  return data;
};

export const getFrequentCustomer = async ({
  startDate = "",
  endDate = "",
  limit = 0,
  page = 1,
  dashboard = false,
  noOfOrders,
  comparisonType,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/customers/frequentCustomer`,
    params: {
      startDate,
      endDate,
      limit,
      page,
      dashboard,
      noOfOrders,
      comparisonType,
    },
  });
  return data;
};
export const getFirstBuyers = async ({
  firstBuyersPerProduct,
  topMostBuyersLength,
  startDate,
  endDate,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/customers/firstCustomers`,
    params: {
      firstBuyersPerProduct,
      topMostBuyersLength,
      startDate,
      endDate,
    },
  });
  return data;
};

export const getValueableCustomer = async ({
  startDate = "",
  endDate = "",
  limit = 0,
  page = 1,
  dashboard = false,
  sortBy,
  noOfOrders,
  comparisonType,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/customers/valueableCustomer`,
    params: {
      startDate,
      endDate,
      limit,
      page,
      dashboard,
      sortBy,
      noOfOrders,
      comparisonType,
    },
  });
  return data;
};

export const getBundleProduct = async ({
  startDate = "",
  endDate = "",
  minThreshold,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/bundles/TopSkuBundle`,
    params: {
      startDate,
      endDate,
      minThreshold,
    },
  });
  return data;
};

export const getBundleProductsCustomer = async ({
  startDate = "",
  endDate = "",
  minThreshold,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/bundles/TopCustomerBundle`,
    params: {
      startDate,
      endDate,
      minThreshold,
    },
  });
  return data;
};

export const getCollectionProducts = async ({ perPage = 20, page = 1, id }) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/fetchCollectionProduct`,
    params: {
      perPage,
      page,
      id,
    },
  });
  return data;
};

export const getCountOfAllOrders = async () => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/orders/getCountOfAllOrders`,
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getOrderComparison = async ({
  firstMonth,
  secondMonth,
  city,
  state,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/comparison/orders`,
    params: {
      firstMonth,
      secondMonth,
      city,
      state,
    },
  });
  return data;
};

export const getRevenueComparison = async ({
  firstMonth,
  secondMonth,
  city,
  state,
}) => {
  const { data } = await API({
    method: "get",
    url: `/api/comparison/revenue`,
    params: {
      firstMonth,
      secondMonth,
      city,
      state,
    },
  });

  let lastYearRevenue = data.lastYearRevenue.map((item) => {
    return {
      x_axis: item.x_axis,
      y_axis: Number(item.y_axis),
    };
  });

  let thisYearRevenue = data.thisYearRevenue.map((item) => {
    return {
      x_axis: item.x_axis,
      y_axis: Number(item.y_axis),
    };
  });

  const sortedData = {
    lastYearRevenue,
    thisYearRevenue,
    thisYearStats: data.thisYearStats,
    lastYearStats: data.lastYearStats,
    currency: data.currency,
  };
  return sortedData;
};

export const getViewsCities = async ({ state }) => {
  const { data } = await API({
    method: "get",
    url: `/api/products/getProductsViewCities`,
    params: {
      state,
    },
  });
  return data;
};

export const getViewsStates = async () => {
  const { data } = await API({
    method: "get",
    url: `/api/products/getProductsViewState`,
  });
  return data;
};

export const getPageViews = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getPageViews`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPageViewsCities = async ({ state }) => {
  const { data } = await API({
    method: "get",
    url: `/api/pages/getPageViewCities`,
    params: {
      state,
    },
  });
  return data;
};

export const getPageViewsStates = async () => {
  const { data } = await API({
    method: "get",
    url: `/api/pages/getPageViewStates`,
  });
  return data;
};

export const getSalesChannels = async () => {
  const { data } = await API({
    method: "get",
    url: `/api/pages/getSalesChannels`,
  });
  return data;
};

export const getProductViews = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/products/getProductViews`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getCustomerJourney = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getCustomerJourney`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSocialMediaCustomers = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getSocialMediaCustomers`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getConversionRatio = async ({ startDate, endDate }) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getConversionRatio`,
      params: {
        startDate,
        endDate,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSalesChannelsViews = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getSalesChannelViews`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getPagesBounceRate = async ({
  region,
  state,
  startDate,
  endDate,
  sortBy,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getPagesBounceRate`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        sortBy,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getProductsAddedToCartButNotSold = async ({
  region,
  state,
  startDate,
  endDate,
  page,
  perPage = 2,
}) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/productsAddedToCartButNotSold`,
      params: {
        city: region,
        state,
        startDate,
        endDate,
        page,
        perPage,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getViewsState = async ({ startDate, endDate, key }) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getViewsStates`,
      params: {
        startDate,
        endDate,
        key,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getViewsCitiess = async ({ startDate, endDate, state, key }) => {
  try {
    const { data } = await API({
      method: "get",
      url: `/api/pages/getViewsCities`,
      params: {
        startDate,
        endDate,
        state,
        key,
      },
    });
    return data;
  } catch (err) {
    console.log(err);
    return err;
  }
};
