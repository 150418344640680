import { Select, Divider, Button, Skeleton } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { MyContext } from "../../ContextApi/MyContext";
import { setConfiguration } from "../../utils/Queries";
import { useQueryClient } from "@tanstack/react-query";
import { TOASTER_ERROR, TOASTER_SUCCESS } from "../../components/Notifications";
import { useCustomers } from "../../hooks";

const ProductsSettings = () => {
  const queryClient = useQueryClient();
  const { setStoreData, storeData, setCurrentPage } = useContext(MyContext);

  const [customerSubmitLoading, setCustomerSubmitLoading] = useState(false);
  const [excludedCustomers, setExcludedCustomers] = useState(
    storeData?.excluded_customers || []
  );
  const [isUpdate, setIsUpdate] = useState(false);

  const { data: customers, loading: customersLoading } = useCustomers();

  const saveCustomers = async () => {
    try {
      setCustomerSubmitLoading(true);
      const data = await setConfiguration({
        data: {
          excluded_customers: excludedCustomers,
        },
      });

      setStoreData(data.storeData);
      setCustomerSubmitLoading(false);
      queryClient.invalidateQueries();

      TOASTER_SUCCESS({
        message: data.message,
        duration: 3,
      });
    } catch (error) {
      setCustomerSubmitLoading(false);
      TOASTER_ERROR({
        message: error.message,
        duration: 3,
      });
    }
  };

  const onAddCustomer = (value) => {
    if (value.length === 0) setIsUpdate(false);
    else setIsUpdate(true);

    setExcludedCustomers(value);
  };

  useEffect(() => {
    setCurrentPage(window.location.pathname);
  }, []);

  return (
    <div className="flex flex-col justify-between h-full">
      <div>
        {/* <PageHeading heading="Product Settings" /> */}
        <div className="p-4 bg-white mb-4 rounded-lg  flex flex-col justify-between">
          <div>
            <span className="side-modal-vendorHeading">
              Customers that you don't want to include in results
            </span>
            <Divider className="!mt-4" />
            <Button
              type="primary"
              className="my-6 ml-auto !block w-44 !font-extrabold !rounded"
              onClick={saveCustomers}
              loading={customerSubmitLoading}
              disabled={customerSubmitLoading || !isUpdate}
            >
              Save
            </Button>
            <Select
              mode="multiple"
              className="!w-full !border border-solid !rounded"
              placeholder="Please select your target customer"
              defaultValue={excludedCustomers?.map((item) => ({
                value: item,
                label: item,
              }))}
              onChange={onAddCustomer}
              options={customers?.map((item) => ({
                value: item,
                label: item,
              }))}
              loading={customersLoading}
              disabled={customersLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSettings;
